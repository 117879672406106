import { d_network, d_transparency, plug_n_play, d_mapping, d_s_mapping, e_system, seamless_integration, intelligent_market, digital_identity, business_growth, credit_solution, credit_optimisation } from "./svg";
export const barandsObj = [
    {
        title: `Distributor's Network`,
        img: d_network,
        desc: 'Get the right distributor to expand the geographical reach and brand visibility.'
    },
    {
        title: `Data Transparency`,
        img: d_transparency,
        desc: 'Get real sales and customer data to make better business decisions.'
    },
    {
        title: `Plug & Play System`,
        img: plug_n_play,
        desc: 'Plug-and-play system to reach any specified geography for expansion'
    },
    {
        title: `Distributor Mapping`,
        img: d_mapping,
        desc: ' Data backed distributor mapping for sustainable growth and better fill rates'
    },
    {
        title: `Demand Supply Mapping`,
        img: d_s_mapping,
        desc: ' Map SKUs as per the need of the micro markets to avoid liquidation and expiry'
    },
    {
        title: `Easy-to-use system`,
        img: e_system,
        desc: 'Simple and easy-to-use system reducing the deployment time'
    },
]

export const distributorObj = [
    {
        title: `Seamless Integration`,
        img: seamless_integration,
        desc: 'Our system integrates with the existing ERPs reducing the hassle to migrate and make the onboarding smooth'
    },
    {
        title: `Intelligent Market Insights`,
        img: intelligent_market,
        desc: 'Building new brand partnerships through a data-backed market trend improves the business hygiene'
    },
    {
        title: `Digital Identity`,
        img: digital_identity,
        desc: 'Digitise the day-to-day operation and grow the business by reducing the fixed costs'
    },
    {
        title: `Business Growth`,
        img: business_growth,
        desc: 'Expand brand portfolio and grow the business through the commerce platform'
    },
    {
        title: `Credit Solutions`,
        img: credit_solution,
        desc: 'Third-party credit support to manage the growing need for working capital'
    },
    {
        title: `Credit Optimisation`,
        img: credit_optimisation,
        desc: 'Optimise credit in the market and increase ROI for your business'
    },
]

export const testimonialsData = [
    {
        name: 'Devon Lane',
        position: 'Position',
        img: 'devon.png',
        info: `“Doco t duis tristique elit duis consectetur adipiscing elit duis tristique sit sit sollicitudin nibh sit amet commodo nulla facilisi nullam vehicula”.`
    },
    {
        name: 'Cameron Williamson',
        position: 'Position',
        img: 'cameron.png',
        info: `“Doco t duis tristique elit duis consectetur adipiscing elit duis tristique sit sit sollicitudin nibh sit amet commodo nulla facilisi nullam vehicula”.`
    },
    {
        name: 'Robert Fox',
        position: 'Position',
        img: 'robert.png',
        info: `“Doco t duis tristique elit duis consectetur adipiscing elit duis tristique sit sit sollicitudin nibh sit amet commodo nulla facilisi nullam vehicula”.`
    },
    {
        name: 'Robert Fox',
        position: 'Position',
        img: 'emp4.png',
        info: `“Doco t duis tristique elit duis consectetur adipiscing elit duis tristique sit sit sollicitudin nibh sit amet commodo nulla facilisi nullam vehicula”.`
    },
]