export const whatGetAtDocoArr = [
    {
        id: 1,
        title: 'Solving the biggest thing',
        desc: 'Solving insanely massive problem of offline retail commerce',
        img: 'solving _biggest_thing.svg'
    },
    {
        id: 2,
        title: 'Changing Indian retail sector',
        desc: 'Make the offline retail sector ready for the next gen-commerce.',
        img: 'changing_indian_retail_sector.svg'
    },
    {
        id: 3,
        title: 'Impact',
        desc: 'Transforming the lives of millions of people who are left behind',
        img: 'impact_illustration.svg'
    },
    {
        id: 4,
        title: 'Learning',
        desc: 'With business and tech innovation, no dearth of learning and update',
        img: 'learning_illustration.svg'
    },
    {
        id: 5,
        title: 'Helping hand',
        desc: 'Professional and Personal Help will always be available',
        img: 'helping_hand.svg'
    },
    {
        id: 6,
        title: 'Fun time',
        desc: 'Let loose with the gang, life is beyond just work',
        img: 'fun_time.svg'
    },
    {
        id: 7,
        title: 'Create wealth in future',
        desc: 'Your wealth goes in the proposition as we sky-rocket and build a 5000X company',
        img: 'create_wealth_in_future.svg'
    },
    {
        id: 8,
        title: 'Fail and learn',
        desc: "Don't be afraid to fail while learning new things",
        img: 'fail_and_learn.svg'
    },
    {
        id: 9,
        title: 'Become a Rockstar',
        desc: 'Become a rockstar with your creative work for your team',
        img: 'become_a_rockstar.svg'
    },
]

export const desktopOurValueArr = [
    [
        {
            title: 'Hustler',
            img: 'hustler_img.svg',
            tooltip:'Roll up your sleeves and get the things done'
        },
        {
            title: 'We all are BOSS',
            img: 'all_are_boss.svg',
            tooltip:"Work like it's your work"
        },
    ],
    [
        {
            title: '80-20 Rule',
            img: '80-20-rule.svg',
            tooltip:"Prioritize things to do 80% of work with 20% effort, Always"
        },
        {
            title: 'Work hard - Party harder',
            img: 'work-hard-party-hard.svg',
            tooltip:"Work like an Artist Party like a Boss"
        },
        {
            title: 'All in',
            img: 'all-in.svg',
            tooltip:" Do like it's the last thing you want to do"
        },
    ],
    [
        {
            title: 'We are family',
            img: 'we-are-family.svg',
            tooltip:"It is We, not I"
        },
        {
            title: 'Integrity',
            img: 'integrity.svg',
            tooltip:"Strong moral principles"
        },

    ],
    [
        {
            title: 'Freedom',
            img: 'freedom.svg',
            tooltip:"Freedom to implement your work"
        },
        {
            title: `Don't fit everything in a same mole frame`,
            img: 'dont-fit-everything.svg',
            tooltip:"Diversity is the beauty"
        },
        {
            title: 'A+ team',
            img: 'a-plus-team.svg',
            tooltip:"Nothing less than the best"
        },
    ],
    [
        {
            title: 'Ownership',
            img: 'ownership.svg',
            tooltip:" You are an Owner, not an employee"
        },
        {
            title: 'Built with heart',
            img: 'built-with-heart.svg',
            tooltip:"Do what you love"
        },

    ],
]

export const mobileOurValueArr = [
    {
        title: 'Hustler',
        img: 'hustler_img.svg',
        tooltip:'Roll up your sleeves and get the things done'
    },
    {
        title: '80-20 Rule',
        img: '80-20-rule.svg',
        tooltip:"Prioritize things to do 80% of work with 20% effort, Always"
    },
    {
        title: 'We are family',
        img: 'we-are-family.svg',
        tooltip:"It is We, not I"
    },
    {
        title: 'Freedom',
        img: 'freedom.svg',
        tooltip:" Freedom to implement your work"
    },
    {
        title: 'Ownership',
        img: 'ownership.svg',
        tooltip:" You are an Owner, not an employee"
    },
    {
        title: 'We all are BOSS',
        img: 'all_are_boss.svg',
        tooltip:"Work like it's your work"
    },
    {
        title: 'Work hard - Party harder',
        img: 'work-hard-party-hard.svg',
        tooltip:"Work like an Artist Party like a Boss"
    },
    {
        title: 'All in',
        img: 'all-in.svg',
        tooltip:" Do like it's the last thing you want to do"
    },
    {
        title: 'Integrity',
        img: 'integrity.svg',
        tooltip:"Strong moral principles"
    },
    {
        title: `Don't fit everything in a same mole frame`,
        img: 'dont-fit-everything.svg',
        tooltip:"Diversity is the beauty"
    },
    {
        title: 'A+ team',
        img: 'a-plus-team.svg',
        tooltip:"Nothing less than the best"
    },
    {
        title: 'Built with heart',
        img: 'built-with-heart.svg',
        tooltip:"Do what you love"
    },
]

export const mindBehindDoco=[
    {
        name:'Jitendra Yadav',
        profile_img:'jitendra_kumar.jpg',
        position:'Co-Founder, CEO',
        linkedin_url:'https://www.linkedin.com/in/jitendra-yadav-76975625/',
        about:'A second-time entrepreneur and alumnus of IIM Lucknow with 10 years of experience. He has spent more than 6 years understanding the Indian retail sector while working with Udaan, 1K Kirana, and his own first startup.'
    },
    {
        name:'Nishant Agarwal',
        profile_img:'nishant_agrawal.jpg',
        position:'Co-Founder, COO',
        linkedin_url:'https://www.linkedin.com/in/nishant-agarwal-4970237a/',
        about:'An MBA from IMT and has 7 years of diversified professional experience in Retail (1K Kirana) and the marketplace (RedDoorz). Domain expertise in sales, operations, and business expansion.'
    },
    /*{
        name:'Rajesh Gupta',
        profile_img:'rajesh_gupta.jpg',
        position:'Co-founder, CTO',
        linkedin_url:'#',
        about:'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit '
    },*/

]

export const ourAdvisors=[
    {
        name:'Jitendra Yadav',
        profile_img:'jitendra_kumar.jpg',
        position:'Co-founder, CEO',
        linkedin_url:'#',
        about:'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit '
    },
    {
        name:'Bhoopendra Kumar',
        profile_img:'bhupendra_singh.jpg',
        position:'Co-founder, CPO',
        linkedin_url:'#',
        about:'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit '
    },
    {
        name:'Nishant Agarwal',
        profile_img:'nishant_agrawal.jpg',
        position:'Co-founder, COO',
        linkedin_url:'#',
        about:'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit '
    },
    {
        name:'Rajesh Gupta',
        profile_img:'rajesh_gupta.jpg',
        position:'Co-founder, CTO',
        linkedin_url:'#',
        about:'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit '
    },

]
