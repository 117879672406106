import React, { useState, useEffect } from "react";
/*End react related import*/
import { register } from "swiper/element/bundle";
/*End third party package import */
import { barandsObj, distributorObj, testimonialsData } from "./data";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
// invoke register function
register();

const Home = (props) => {
  const [activeBarnd, setActiveBrand] = useState(0);
  const [activeDistributor, setActiveDistributor] = useState(0);
  const deviceW = window.outerWidth;
  return (
    <>
      <Header />
      <section className="hero_section pos-relative" id="heroSection">
        <div className="container">
          <div className="row">
            <div className="left_col">
              <h1 className="title">
                Empowering Brands to reach 1 Billion Consumers in Bharat through
                offline retail
              </h1>
              <p className="sub_title">
                Unlock distributor's network for brands in general trade through
                Data, <strong>ML/AI</strong>-driven platform
              </p>
              <a
                href="https://forms.gle/QKCxjGcfTsjZZQhd6"
                className="btn_dark"
                target='_blank'
              >
                Get Started Free
              </a>
            </div>
            {/* left col end */}
            <div className="right_col">
              <img
                src="./assets/img/home_hero.svg"
                alt="Hero image"
                className="img_responsive"
              />
            </div>
            {/* right col end */}
          </div>
          {/* row end */}
        </div>
        <div className="bg_primary--blend"></div>
        {/* bg blend div */}
      </section>
      {/* hero section end */}

      <section className="problem_section" id="problemSection">
        <div className="container">
          <h1 className="main_heading">
            The <span className="highlight_text">Problem</span>
          </h1>
          <div className="row">
            <div className="left_col pos-relative">
              <div className="oval_div">
                <img
                  className="problem_img img_responsive"
                  src="./assets/img/problem_img.svg"
                  alt="Problem image"
                />
              </div>
            </div>
            <div className="right_col">
              <h2 className="title">Unorganised and Fragmented</h2>
              <p className="desc">
                With a $200B+ market size, local & regional brands are restricted to their local geographies due to high sales & distribution costs and lack of market insights compared to national brands.
              </p>
              <p className="desc">
              At the same time, distributors need better visibility of brands, support on the rotation, demand matching and digital identity. In this space, problems remain largely unsolved. The market is highly fragmented, majorly offline, lacks trust and is low on discovery.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* problem section end */}

      <section className="solution_section" id="solutionSection">
        <div className="container">
          <h1 className="main_heading">
            <span className="highlight_text">One-point solution </span>for all
          </h1>
          <div className="row brand_row">
            <div className="left_col pos-relative">
              {/* for mobile view */}
              <div className="mob-show">
                <h2 className="title">Values for Brands</h2>
                <p className="text">
                Explore newer geographies and increase your brand's reach beyond current sales channels
                </p>
              </div>
              {/* for mobile view end */}
              <img src="./assets/img/points-img.svg" className="bg_dots" />
              <div className="show_hover_card">
                <h2 className="card_title">{barandsObj[activeBarnd].title}</h2>
                <i
                  className="icon_contianer"
                  dangerouslySetInnerHTML={{
                    __html: barandsObj[activeBarnd].img,
                  }}
                ></i>
                <p>{barandsObj[activeBarnd].desc}</p>
              </div>
            </div>
            <div className="right_col">
              {/* for desktop view */}
              <div className="dsk-show">
                <h2 className="title">Values for Brands</h2>
                <p className="text">
                Explore newer geographies and increase your brand's reach beyond current sales channels
                </p>
              </div>
              {/* for desktop view  end */}
              <div className="hover_card_wrapper">
                {barandsObj.map((item, index) => (
                  <div
                    className={`hover_card ${
                      activeBarnd == index ? "active" : ""
                    }`}
                    key={index}
                    onMouseEnter={() => setActiveBrand(index)}
                  >
                    <i
                      className="icon_contianer"
                      dangerouslySetInnerHTML={{ __html: item.img }}
                    ></i>
                    <h2 className="hover_card_title">{item.title}</h2>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* barand row end */}
          <div className="row distributor_row">
            <div className="left_col">
              {/* for desktop view */}
              <div className="dsk-show">
                <h2 className="title">Value for Distributors</h2>
                <p className="text">
                Be part of the E-Commerce revolution by digitising the operations and growing business through new brand partnerships
                </p>
              </div>
              {/* for desktop view end*/}
              <div className="hover_card_wrapper">
                {distributorObj.map((item, index) => (
                  <div
                    className={`hover_card ${
                      activeDistributor == index ? "active" : ""
                    }`}
                    key={index}
                    onMouseEnter={() => setActiveDistributor(index)}
                  >
                    <i
                      className="icon_contianer"
                      dangerouslySetInnerHTML={{ __html: item.img }}
                    ></i>
                    <h2 className="hover_card_title">{item.title}</h2>
                  </div>
                ))}
              </div>
            </div>
            <div className="right_col pos-relative">
              {/* for mobile view */}
              <div className="mob-show">
                <h2 className="title">Value for Distributors</h2>
                <p className="text">
                Be part of the E-Commerce revolution by digitising the operations and growing business through new brand partnerships
                </p>
              </div>
              {/* for mobile view end*/}
              <img src="./assets/img/points-img.svg" className="bg_dots" />
              <div className="show_hover_card">
                <h2 className="card_title">
                  {distributorObj[activeDistributor].title}
                </h2>
                <i
                  className="icon_contianer"
                  dangerouslySetInnerHTML={{
                    __html: distributorObj[activeDistributor].img,
                  }}
                ></i>
                <p>{distributorObj[activeDistributor].desc}</p>
              </div>
            </div>
          </div>
          {/* distributors row end */}
        </div>
      </section>
      {/* solution section end */}

      <section className="ecosystem_solution" id="ecosystemSection">
        <div className="container">
          <h1 className="main_heading">
            Our
            <span className="highlight_text">Ecosystem</span>
          </h1>
          <img
            alt="ecosystem"
            srcset="./assets/img/ecosystem_mobile.svg 480w"
            src="./assets/img/ecosystem.svg"
            className="img_responsive"
            />
        </div>
      </section>
      {/* ecosytem section end */}

      <section className="doco_part_section pos-relative" id="docoPartSection">
        <div className="bg_white_top--blend"></div>
        {/* blend background div end */}
        <div className="container">
          <h1 className="main_heading">
            How do you be
            <span className="highlight_text"> part of DOCO?</span>
          </h1>
          <div className="doco_part_card_wrapper">
            <div className="doco_part_card">
              <h2 className="card_title mob-show">BRAND</h2>
              <div className="doco_img_div">
                <img src="./assets/img/how_brand_icon.png" alt="brand vector" />
              </div>
              {/* card image wrapper */}
              <div className="doco_card_text_div">
                <h2 className="card_title dsk-show">BRAND</h2>
                <ul className="card_list">
                  <li>Plug-n-Play Offline Distributor's Network</li>
                  <li>Visibility to the Secondary Sales</li>
                </ul>
              </div>
              <div className="card_btn_div">
                <a href="https://forms.gle/QKCxjGcfTsjZZQhd6" target='_blank' className="btn_dark">
                  Join as Brand
                </a>
              </div>
            </div>
            {/* doco card1 div */}
            <div className="doco_part_card">
              <h2 className="card_title mob-show">DISTRIBUTOR</h2>
              <div className="doco_img_div">
                <img src="./assets/img/how_dist_icon.png" alt="brand vector" />
              </div>
              {/* card image wrapper */}
              <div className="doco_card_text_div">
                <h2 className="card_title dsk-show">DISTRIBUTOR</h2>
                <ul className="card_list">
                  <li>Better ROI by optimising operations</li>
                  <li>Add new brands through digital identity</li>
                </ul>
              </div>
              <div className="card_btn_div">
                <a href="https://forms.gle/QKCxjGcfTsjZZQhd6" target='_blank' className="btn_dark">
                  Join as Distributor
                </a>
              </div>
            </div>
            {/* doco card2 div */}
            <div className="doco_part_card">
              <h2 className="card_title mob-show">JOIN OUR TEAM</h2>
              <div className="doco_img_div">
                <img src="./assets/img/how_join_icon.png" alt="brand vector" />
              </div>
              {/* card image wrapper */}
              <div className="doco_card_text_div">
                <h2 className="card_title dsk-show">JOIN OUR TEAM</h2>
                <ul className="card_list">
                  <li>Create a high impact in your career within a limited time.</li>
                  <li>Generate real value in the retail segment.</li>
                </ul>
              </div>
              <div className="card_btn_div">
                <a
                  href="https://forms.gle/aY3VGqtGGGBrMvBn9"
                  className="btn_dark"
                  target='_blank'
                >
                  Join our Team
                </a>
              </div>
            </div>
            {/* doco card3 div */}
          </div>
        </div>
        {/* container end */}
        <div className="bg_primary--blend"></div>
        {/* bg blend div */}
      </section>
      {/* how to section end */}

      <section
        className="testimonials_section"
        id="testimonialSection"
        style={{ display: "none" }}
      >
        <div className="container">
          <h1 className="main_heading">
            <span className="highlight_text">Testimonials</span>
          </h1>
          <swiper-container
            slides-per-view={deviceW <= 500 ? 1.05 : deviceW <= 768 ? 2 : 3.15}
            navigation={false}
            pagination={deviceW <= 500 ? true : false}
            scrollbar={
              deviceW <= 500 ? false : { el: "swiper-scrollbar", hide: true }
            }
            spaceBetween={10}
            autoPlay={true}
            speed={1000}
            autoplay={{ delay: 1000 }}
            className="testimonials_slider"
          >
            {testimonialsData.map((item, index) => (
              <swiper-slide key={index}>
                <div className="testimonials_card_wrap">
                  <div className="testimonials_card">
                    <div className="card_avatar_section">
                      <div className="avatar_img_div">
                        <img src={`./assets/img/${item.img}`} alt="" />
                      </div>
                      <div className="avatar_text">
                        <h2>{item.name}</h2>
                        <p>
                          {item.position}, <i>Company</i>
                        </p>
                      </div>
                    </div>
                    {/* avatart section end */}
                    <div className="avatar_info">{item.info}</div>
                  </div>
                </div>
              </swiper-slide>
            ))}

            <swiper-scrollbar></swiper-scrollbar>
          </swiper-container>
        </div>
      </section>
      {/* Testimonials section end */}
      <Footer />
    </>
  );
};

export default Home;
