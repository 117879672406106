import { Link } from "react-router-dom";
const Footer = () => (
  <footer className="footer_section">
    <div className="container">
      <Link to="/" className="footer_logo_link">
        <img src="./assets/img/white-doco-logo.svg" alt="doco" />
      </Link>
      <div className="footer-sec_wrapper">
        <div className="footer_sec_1">
          <div className="footer_dull_text">
            <p>
              Empowering Brands to reach 1 Billion Consumers in Bharat through
              offline retail
            </p>
            <p>
              Unlock distributor's network for brands in general trade through
              Data, ML/AI-driven platform
            </p>
          </div>

          <a
            href="https://forms.gle/QKCxjGcfTsjZZQhd6"
            className="btn_white_border"
          >
            Get Started Free
          </a>

          <p className="footer_text_copyright dsk-show">© 2023 Doco</p>
        </div>
        {/* section first end */}
        <div className="footer_sec_2">
          <h2 className="footer_link_title">Quick Links</h2>
          <ul className="ul_unset">
            <li>
              <a href="/#solutionSection" className="footer_link">
                Our Solution
              </a>
            </li>
            <li>
              <a href="/#ecosystemSection" className="footer_link">
                Our Ecosystem
              </a>
            </li>
            <li>
              <Link to="/doco-life" className="footer_link">
                Life at Doco
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer_sec_3">
          <h2 className="footer_link_title">More Details</h2>
          <ul className="ul_unset">
            <li>
              <Link to="#" className="footer_link">
                Privacy Settings ​
              </Link>
            </li>
            <li>
              <Link to="#" className="footer_link">
                Terms & Conditions
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer_sec_4">
          <h2 className="footer_link_title">Contact Us</h2>
          <ul className="ul_unset">
            <li>
              <p className="contact_sec_p">
                <span className="p_title">Contact No. </span>
                <span className="p_value">+91 8897237272</span>
              </p>
            </li>
            <li>
              <p className="contact_sec_p">
                <span className="p_title">Email </span>
                <span className="p_value">mydoco2022@gmail.com</span>
              </p>
            </li>
            <li>
              <p className="contact_sec_p">
                <span className="p_title">Address </span>
                <span className="p_value">
                  Sector 6 Raj Nagar , Ghaziabad 201002
                </span>
              </p>
            </li>
          </ul>
          <div className="footer_social_icons">
            <p className="footer_text_copyright mob-show">© 2023 Doco</p>
            <a
              href="https://www.linkedin.com/company/mydoco/"
              target="_blank"
              className="social_icon_link"
            >
              <img src="./assets/img/linkedin.svg" alt="" />
            </a>
            <button className="social_icon_link">
              <img src="./assets/img/twitter.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </footer>
);
export default Footer;
