import './App.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './components/Home/Home';
import DocoLife from './components/DocoLife/DocoLife';
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <PageLayOut
        title="DOCO | Empowering Brands to reach 1 Billion Consumers in Bharat through offline retail"
        meta_description="Unlock distributor's network for brands in general trade through Data, ML/AI-driven platform"
        children={Home}
      />,
    },
    {
      path: "/doco-life",
      element: <PageLayOut
        title="Life at DOCO | Join us in building retail commerce for Bharat"
        meta_description="Unlock distributor's network for brands in general trade through Data, ML/AI-driven platform"
        children={DocoLife} />,
    },
    {
      path: "/*",
      element: <h1 style={{ display: 'flex', height: '100vh', maxWidth: '100%', justifyContent: 'center', alignItems: 'center', color: 'red' }}>404 Page not found </h1>,
    },
  ]);
  return (
    <HelmetProvider>
      <main className='main_wrapper'>
        <RouterProvider router={router} />
      </main>
    </HelmetProvider>
  );
}


export default App;


const PageLayOut = (props) => {
  return (<>
    <Helmet>
      <>
        <meta name="description" content={props.meta_description} />
        <title>{props.title}</title>
      </>
    </Helmet>
    <props.children />
  </>
  )
}



