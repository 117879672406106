import React, { useLayoutEffect, useState } from "react";
/*End react related import*/
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet-async";
import { down_angle } from "./svg";
import {
  whatGetAtDocoArr,
  desktopOurValueArr,
  mobileOurValueArr,
  mindBehindDoco,
} from "./data";
const DocoLife = (props) => {
  const [activeAccordion, setActiveAccordion] = useState(0); //variable for desktop view
  const [activeSmAccordion, setActiveSmAccordion] = useState(0); //variable for mobile view
  const [pageLoadding, setPageLoading] = useState(true);
  const handleAccordion = (ind) => {
    let activeAccordionDiv = document.querySelectorAll(".collapsable");
    if (activeSmAccordion === ind) {
      activeAccordionDiv?.forEach((item) => {
        item.style.maxHeight = 0 + "px";
      });
      setActiveSmAccordion(false);
    } else {
      activeAccordionDiv?.forEach((item, ind2) => {
        if (ind === ind2) {
          const height = item.scrollHeight + "px";
          console.log(height);
          item.style.maxHeight = height;
        } else {
          item.style.maxHeight = 0 + "px";
        }
      });
      setActiveSmAccordion(ind);
    }
  };

  useLayoutEffect(() => {
    let html = document.querySelector("html");
    let isLoad = new Promise((resolve, reject) => {
      html.scrollTop = 0;
      resolve();
    });
    isLoad.then(() => setPageLoading(false));
  }, []);
  return (
    <div style={{ display: pageLoadding ? "none" : "" }}>
      <Header />
      <section className="hero_section docolife_hero_section pos-relative">
        <div className="container">
          <div className="row">
            <div className="left_col">
              <h1 className="title">
                Join us{" "}
                <span className="fw_500">in building retail commerce for </span>
                Bharat
              </h1>
            </div>
            {/* left col end */}
            <div className="right_col">
              <img
                src="./assets/img/doco_life_hero_img.svg"
                alt="Hero image"
                className="img_responsive"
              />
            </div>
            {/* right col end */}
          </div>
          {/* row end */}
        </div>
        <div className="bg_primary--blend"></div>
        {/* bg blend div */}
      </section>
      {/* hero section end */}

      <section className="what_you_get_section">
        <div className="container">
          <h1 className="main_heading">
            What <span className="highlight_text">You will get</span> at DOCO
          </h1>
          <div className="row">
            <div className="left_col">
              {whatGetAtDocoArr.map((item, index) => (
                <div className="card" key={index}>
                  <button
                    className={`accordion_btn dsk-show ${
                      activeAccordion === index ? "active" : ""
                    }`}
                    onClick={() => setActiveAccordion(index)}
                  >
                    <span>{item.title}</span>
                  </button>
                  {/* desktop view button end*/}
                  <button
                    className={`accordion_btn mob-show ${
                      activeSmAccordion === index ? "active" : ""
                    }`}
                    onClick={() => handleAccordion(index)}
                  >
                    <span>{item.title}</span>
                    <i
                      className="down_angle"
                      dangerouslySetInnerHTML={{ __html: down_angle }}
                    ></i>
                  </button>
                  {/* mobile view button end*/}
                  <div
                    className={`collapsable ${
                      activeSmAccordion === index ? "active" : ""
                    }`}
                  >
                    <p>{item.desc}</p>
                    <img
                      className="img_responsive"
                      src={`./assets/img/${item.img}`}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="right_col dsk-show">
              <p className="desc">
                {whatGetAtDocoArr[activeAccordion || 0].desc}
              </p>
              <img
                className="img_responsive"
                src={`./assets/img/${
                  whatGetAtDocoArr[activeAccordion || 0].img
                }`}
              />
            </div>
          </div>
        </div>
      </section>
      {/* What You will get at DOCO section end */}

      <section className="our_value_section">
        <div className="container">
          <h1 className="main_heading">
            Our <span className="highlight_text">Value</span>
          </h1>
          <div className="dsk-show">
            <div className="our_value_card_row">
              <div className="our_value_card_col">
                {desktopOurValueArr[0].map((item, i) => (
                  <div className="our_value_card" key={i}>
                    <div className="card_img">
                      <img src={`./assets/img/${item.img}`} />
                    </div>
                    <div className="card-content">
                      <p>{item.title}</p>
                      <p className="tooltip">{item.tooltip}</p>
                    </div>
                  </div>
                ))}
              </div>
              {/* col-1 end */}
              <div className="our_value_card_col">
                {desktopOurValueArr[1].map((item, i) => (
                  <div className="our_value_card" key={i}>
                    <div className="card_img">
                      <img src={`./assets/img/${item.img}`} />
                    </div>
                    <div className="card-content">
                      <p>{item.title}</p>
                      <p className="tooltip">{item.tooltip}</p>
                    </div>
                  </div>
                ))}
              </div>
              {/* col-2 end */}
              <div className="our_value_card_col">
                {desktopOurValueArr[2].map((item, i) => (
                  <div className="our_value_card" key={i}>
                    <div className="card_img">
                      <img src={`./assets/img/${item.img}`} />
                    </div>
                    <div className="card-content">
                      <p>{item.title}</p>
                      <p className="tooltip">{item.tooltip}</p>
                    </div>
                  </div>
                ))}
              </div>
              {/* col-3 end */}
              <div className="our_value_card_col">
                {desktopOurValueArr[3].map((item, i) => (
                  <div className="our_value_card" key={i}>
                    <div className="card_img">
                      <img src={`./assets/img/${item.img}`} />
                    </div>
                    <div className="card-content">
                      <p>{item.title}</p>
                      <p className="tooltip">{item.tooltip}</p>
                    </div>
                  </div>
                ))}
              </div>
              {/* col-4 end */}
              <div className="our_value_card_col">
                {desktopOurValueArr[4].map((item, i) => (
                  <div className="our_value_card" key={i}>
                    <div className="card_img">
                      <img src={`./assets/img/${item.img}`} />
                    </div>
                    <div className="card-content">
                      <p>{item.title}</p>
                      <p className="tooltip">{item.tooltip}</p>
                    </div>
                  </div>
                ))}
              </div>
              {/* col-5 end */}
            </div>
          </div>
          {/* desktop view end */}
          <div className="mob-show">
            <div className="our_value_card_row">
              {mobileOurValueArr.map((item, i) => (
                <div className="our_value_card_col" key={i}>
                  <div className="our_value_card">
                    <div className="card_img">
                      <img src={`./assets/img/${item.img}`} />
                    </div>
                    <div className="card-content">
                      <p>{item.title}</p>
                      <p className="tooltip">{item.tooltip}</p>
                    </div>
                  </div>
                </div>
              ))}
              {/* col-1 end */}
            </div>
          </div>
          {/* mobile view end */}
        </div>
      </section>
      {/* our value section end */}

      <section className="minds_behind_section pos-relative">
        <div className="bg_white_top--blend"></div>
        {/* blend background div end */}
        <div className="container">
          <h1 className="main_heading">
            <span className="highlight_text">Minds behind</span>Doco
          </h1>

          <div className="minds_behind_row">
            {mindBehindDoco.map((person, ind) => (
              <div className="minds_behind_col" key={ind}>
                <div className="avatar_wrapper">
                  <img src={`./assets/img/${person.profile_img}`} />
                </div>
                <h2 className="avatar_name">{person.name}</h2>
                <p className="avatar_position">{person.position}</p>
                <p>{person.about}</p>
                <div className="social_icons_wrapper">
                  <a
                    href={person.linkedin_url}
                    target="_blank"
                    className="social_icon linkedin"
                  >
                    <img src="./assets/img/linkedin.svg" alt="" />
                  </a>
                </div>
              </div>
            ))}
          </div>
          {/* minds_behind_row end */}
        </div>
        {/* container end */}
        <div className="bg_primary--blend"></div>
        {/* bg blend div */}
      </section>
      {/* minds behind section end */}

      <section className="minds_behind_section our-advisors pos-relative">
        <div className="container">
          <div style={{ display: "none" }}>
            <h1 className="main_heading">
              Our<span className="highlight_text">Advisors</span>
            </h1>

            <div className="minds_behind_row">
              {mindBehindDoco.map((person, ind) => (
                <div className="minds_behind_col" key={ind}>
                  <div className="avatar_wrapper">
                    <img src={`./assets/img/${person.profile_img}`} />
                  </div>
                  <h2 className="avatar_name">{person.name}</h2>
                  <p className="avatar_position">{person.position}</p>
                  <p>{person.about}</p>
                  <div className="social_icons_wrapper">
                    <a
                      href={person.linkedin_url}
                      target="_blank"
                      className="social_icon linkedin"
                    >
                      <img src="./assets/img/linkedin.svg" alt="" />
                    </a>
                  </div>
                </div>
              ))}
            </div>
            {/* minds_behind_row end */}
          </div>
          <div className="we_are_hiring_card">
            <div className="we_are_hiring_card_img">
              <img src="./assets/img/we_are_hiring.svg" />
            </div>
            <div className="we_are_hiring_card_content">
              <p>
                We are looking for talented & highly motivated folks on the
                roller coaster journey of the DOCO to achieve 5000X growth!
              </p>
              <a
                href="https://forms.gle/aY3VGqtGGGBrMvBn9"
                target="_blank"
                className="btn_dark"
              >
                Are you the ONE? Find A Role
              </a>
            </div>
          </div>

          {/* we are hiring card end */}
        </div>
        {/* container end */}
      </section>
      {/* our-advisors section end */}
      <Footer />
    </div>
  );
};

export default DocoLife;
