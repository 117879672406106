import React, { useEffect, useState } from "react";
/*End react related import*/
import { Link } from "react-router-dom";
const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [scroll, setScroll] = useState(false);
  const toggleNavigation = () => {
    setIsNavOpen(!isNavOpen);
  };
  useEffect(() => {
    let hasvalue = window.location.hash;
    const scrollElement = document.querySelector("html");
    if (hasvalue) {
      let scrollDiv = document.querySelector(hasvalue);
      scrollElement.scrollTop = scrollDiv?.offsetTop - 100;
    } else {
      scrollElement.scrollTop = 0;
    }

    document.addEventListener("scroll", () => {
      const topScroll = scrollElement.scrollTop;
      const header = document.querySelector(".site_header");
      if (topScroll && topScroll > 150) {
        header.classList.add("fixed");
      } else {
        header.classList.remove("fixed");
      }
    });
  }, [scroll]);
  return (
    <header className={`site_header ${isNavOpen ? "active" : ""}`}>
      <div className="container">
        <nav>
          <section className="logo_sec">
            <Link to="/">
              <img src="./assets/img/doco_dark_logo.svg" alt="" />
            </Link>

            <button
              className={`toggler ${isNavOpen ? "active" : ""}`}
              onClick={() => toggleNavigation()}
            >
              <i></i>
              <i></i>
              <i></i>
            </button>
          </section>
          <section className="header_links_sec">
            <Link
              to="/#solutionSection"
              className="link"
              onClick={() => {
                setIsNavOpen(false);
                setScroll(!scroll);
              }}
            >
              Our Solution
            </Link>
            <Link
              to="/#ecosystemSection"
              className="link"
              onClick={() => {
                setIsNavOpen(false);
                setScroll(!scroll);
              }}
            >
              Our Ecosystem
            </Link>
            {/* <Link
              to="/#testimonialSection"
              className="link"
              onClick={() => {
                setIsNavOpen(false);
                setScroll(!scroll);
              }}
            >
              Testimonials
            </Link> */}
            <Link
              to="/doco-life"
              className="link"
              onClick={() => {
                setIsNavOpen(false);
                setScroll(!scroll);
              }}
            >
              Life at Doco
            </Link>
            <a
              href="https://app.mydoco.in"
              className="btn_dark"
              target='_blank'
              onClick={() => {
                setIsNavOpen(false);
              }}
            >
              Retailer Signup
            </a>
          </section>
        </nav>
      </div>
    </header>
  );
};
export default Header;
